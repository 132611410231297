import * as Turbo from "@hotwired/turbo"
import Alpine from "alpinejs"

window.Alpine = Alpine

Alpine.data("Application", () => ({
  drawerOpen: false,
  closeDrawer() {
    this.drawerOpen = false
  },
  openDrawer() {
    this.drawerOpen = true
  }
}))

Alpine.start()
